import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../../configurations";
import {
    VerificationError,
    VerificationOrder,
    VerificationOrderFilters,
    VerificationOrderResponse,
    VerificationStatistics,
} from "../../interfaces";

export class VerificationRepository {
    private lmBootApi: AxiosInstance;

    constructor() {
        this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
    }

    getVerificationOrders(data: VerificationOrderFilters): Promise<AxiosResponse<VerificationOrderResponse>> {
        return this.lmBootApi.get<VerificationOrderResponse>("/verification", { params: data });
    }
    getVerificationOrderById(orderCode: number): Promise<AxiosResponse<VerificationOrder>> {
        return this.lmBootApi.get<VerificationOrder>(`/verification/${orderCode}`);
    }
    createVerificationErrors(data: VerificationError): Promise<AxiosResponse<{ message: string }>> {
        return this.lmBootApi.post<{ message: string }>(`/verification/save`, data);
    }
    nextStepVerification(orderCode: number): Promise<AxiosResponse<{ message: string }>> {
        return this.lmBootApi.post<{ message: string }>(`/verification/next-step/${orderCode}`);
    }
    getVerificationStatistics(): Promise<AxiosResponse<VerificationStatistics[]>> {
        return this.lmBootApi.get<VerificationStatistics[]>("/verification/user/statistics");
    }
}
