import { TransactionRepository } from "../repositories";
import { TransactionRequest, TransactionFilters } from "../../interfaces";

export class TransactionProcess {
    private transactionRepository: TransactionRepository;

    constructor() {
        this.transactionRepository = new TransactionRepository();
    }

    async getTransactions(filters: TransactionFilters): Promise<TransactionRequest> {
        const response = await this.transactionRepository.getTransactions(filters);
        const {
            data: { data, meta },
        } = response;
        return {
            transactionData: data,
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
            hasPreviousPage: meta.hasPreviousPage,
            hasNextPage: meta.hasNextPage,
        };
    }
}
