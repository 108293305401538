import { TransactionTypes } from "../types";
import { TransactionStore } from "../../interfaces";
import { AnyAction } from "redux";

const initialState: TransactionStore = {
    transactionList: null,
    filters: null,
    loading: false,
    success: true,
};

export const transactions = (
    state = initialState,
    action: { type: string; payload: TransactionStore } | AnyAction,
): TransactionStore => {
    const { loading, success, transactionList, filters } = action.payload || {};
    switch (action.type) {
        case TransactionTypes.GET_TRANSACTIONS_LOADING:
            return {
                ...state,
                loading,
            };
        case TransactionTypes.GET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                transactionList,
                filters,
                loading,
                success,
            };
        case TransactionTypes.GET_TRANSACTIONS_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case TransactionTypes.CLEAR_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                transactionList: null,
            };
        default:
            return state;
    }
};
