import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../../configurations";
import { Transaction } from "../../interfaces";
import { TransactionFilters } from "../../interfaces/transaction.interface";

type TransactionServeData = {
    data: Transaction[];
    meta: {
        page: number;
        take: number;
        itemCount: number;
        pageCount: number;
        hasPreviousPage: boolean;
        hasNextPage: boolean;
    };
};
export class TransactionRepository {
    private lmBootApi: AxiosInstance;

    constructor() {
        this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
    }

    getTransactions(data: TransactionFilters): Promise<AxiosResponse<TransactionServeData>> {
        return this.lmBootApi.get<TransactionServeData>("/commissions/movements", { params: data });
    }
}
