import { OrderFilters, OrderRequest } from "../../interfaces";
import { OrderRepository } from "../repositories";
export class OrderProcess {
    private orderRepository: OrderRepository;

    constructor() {
        this.orderRepository = new OrderRepository();
    }

    async getOrders(filters: OrderFilters): Promise<OrderRequest> {
        const response = await this.orderRepository.getOrders(filters);
        const {
            data: { data, meta },
        } = response;
        return {
            orderData: data,
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
            hasPreviousPage: meta.hasPreviousPage,
            hasNextPage: meta.hasNextPage,
        };
    }
    async getSteps(): Promise<OrderRequest> {
        const response = await this.orderRepository.getSteps();
        return {
            stepList: response.data,
        };
    }
}
