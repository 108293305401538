import { AnyAction } from "redux";
import { VerificationOrderStore } from "../../interfaces";
import { VerificationTypes } from "../types";

const initialState: VerificationOrderStore = {
    verificationOrders: null,
    selectedVerificationOrder: null,
    verificationStatistics: null,
    meta: null,
    filters: null,
    loading: false,
    success: true,
};

export const verifications = (
    state = initialState,
    action: { type: string; payload: VerificationOrderStore } | AnyAction,
): VerificationOrderStore => {
    const { verificationOrders, selectedVerificationOrder, verificationStatistics, meta, filters, loading, success } =
        action.payload || {};
    switch (action.type) {
        case VerificationTypes.GET_VERIFICATION_ORDERS_LOADING:
            return {
                ...state,
                loading,
            };
        case VerificationTypes.GET_VERIFICATION_ORDERS_SUCCESS:
            return {
                ...state,
                verificationOrders,
                meta,
                filters,
                loading,
                success,
            };
        case VerificationTypes.GET_VERIFICATION_ORDERS_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case VerificationTypes.GET_VERIFICATION_ORDER_BY_ID_LOADING:
            return {
                ...state,
                loading,
            };
        case VerificationTypes.GET_VERIFICATION_ORDER_BY_ID_SUCCESS:
            return {
                ...state,
                selectedVerificationOrder,
                loading,
                success,
            };
        case VerificationTypes.GET_VERIFICATION_ORDER_BY_ID_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case VerificationTypes.CLEAR_VERIFICATION_ORDER_SUCCESS:
            return {
                ...state,
                selectedVerificationOrder: null,
                loading,
            };
        case VerificationTypes.CREATE_VERIFICATION_ERROR_LOADING:
            return {
                ...state,
                loading,
            };
        case VerificationTypes.CREATE_VERIFICATION_ERROR_SUCCESS:
            return {
                ...state,
                loading,
                success,
            };
        case VerificationTypes.CREATE_VERIFICATION_ERROR_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case VerificationTypes.NEXT_STEP_VERIFICATION_LOADING:
            return {
                ...state,
                loading,
            };
        case VerificationTypes.NEXT_STEP_VERIFICATION_SUCCESS:
            return {
                ...state,
                loading,
                success,
            };
        case VerificationTypes.NEXT_STEP_VERIFICATION_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case VerificationTypes.GET_VERIFICATION_STATISTICS_LOADING:
            return {
                ...state,
                loading,
            };
        case VerificationTypes.GET_VERIFICATION_STATISTICS_SUCCESS:
            return {
                ...state,
                verificationStatistics,
                loading,
                success,
            };
        case VerificationTypes.GET_VERIFICATION_STATISTICS_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        default:
            return state;
    }
};
