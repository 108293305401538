import { CommissionTypes } from "../types";
import { CommissionProcess } from "../../api/process/commission.process";
import { Dispatch } from "redux";
import { CommissionFilters, PayCommissions } from "../../interfaces";

export const getCommissionsAction =
    (filters: CommissionFilters) =>
    async (
        dispatch: Dispatch,
    ): Promise<{
        type: CommissionTypes;
        payload: {
            loading: boolean;
            success: boolean;
        };
    }> => {
        dispatch({
            type: CommissionTypes.GET_COMMISSIONS_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const commissionProcess = new CommissionProcess();
            const data = await commissionProcess.getCommissions(filters);
            return dispatch({
                type: CommissionTypes.GET_COMMISSIONS_SUCCESS,
                payload: {
                    commissionList: data,
                    filters,
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            console.log(error);

            return dispatch({
                type: CommissionTypes.GET_COMMISSIONS_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };
export const getCommissionsInfoAction =
    (commissionsIds: string) =>
    async (
        dispatch: Dispatch,
    ): Promise<{
        type: CommissionTypes;
        payload: {
            loading: boolean;
            success: boolean;
        };
    }> => {
        dispatch({
            type: CommissionTypes.GET_COMMISSIONS_INFO_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const commissionProcess = new CommissionProcess();
            const data = await commissionProcess.getCommissionsInfo(commissionsIds);
            return dispatch({
                type: CommissionTypes.GET_COMMISSIONS_INFO_SUCCESS,
                payload: {
                    selectedCommissionsInfo: data.selectedCommissionsInfo,
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            console.log(error);

            return dispatch({
                type: CommissionTypes.GET_COMMISSIONS_INFO_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };
export const getWalletByClientCodeAction =
    (clientCode: string) =>
    async (
        dispatch: Dispatch,
    ): Promise<{
        type: CommissionTypes;
        payload: {
            loading: boolean;
            success: boolean;
        };
    }> => {
        dispatch({
            type: CommissionTypes.GET_WALLET_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const commissionProcess = new CommissionProcess();
            const data = await commissionProcess.getWalletByClientCode(clientCode);
            return dispatch({
                type: CommissionTypes.GET_WALLET_SUCCESS,
                payload: {
                    wallet: data.wallet,
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            return dispatch({
                type: CommissionTypes.GET_WALLET_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };

export const createPayCommissions =
    (payCommissionData: PayCommissions) =>
    async (
        dispatch: Dispatch,
    ): Promise<{
        type: CommissionTypes;
        payload: {
            loading: boolean;
            success: boolean;
        };
    }> => {
        dispatch({
            type: CommissionTypes.CREATE_PAY_COMMISSIONS_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const commissionProcess = new CommissionProcess();
            await commissionProcess.createPayCommissions(payCommissionData);
            return dispatch({
                type: CommissionTypes.CREATE_PAY_COMMISSIONS_SUCCESS,
                payload: {
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            return dispatch({
                type: CommissionTypes.CREATE_PAY_COMMISSIONS_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };

export const clearCommissions = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: CommissionTypes.CLEAR_COMMISSIONS_SUCCESS,
    });
};

export const getPaymentMethodListAction =
    () =>
    async (
        dispatch: Dispatch,
    ): Promise<{
        type: CommissionTypes;
        payload: {
            loading: boolean;
            success: boolean;
        };
    }> => {
        dispatch({
            type: CommissionTypes.GET_PAYMENT_METHOD_FAILURE,
            payload: {
                loading: true,
            },
        });
        try {
            const commissionProcess = new CommissionProcess();
            const data = await commissionProcess.getPaymentMethodList();
            return dispatch({
                type: CommissionTypes.GET_PAYMENT_METHOD_SUCCESS,
                payload: {
                    paymentMethodList: data,
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            console.log(error);

            return dispatch({
                type: CommissionTypes.GET_PAYMENT_METHOD_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };
