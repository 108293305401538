export enum VerificationTypes {
    GET_VERIFICATION_ORDERS_LOADING = "GET_VERIFICATION_ORDERS_LOADING",
    GET_VERIFICATION_ORDERS_SUCCESS = "GET_VERIFICATION_ORDERS_SUCCESS",
    GET_VERIFICATION_ORDERS_FAILURE = "GET_VERIFICATION_ORDERS_FAILURE",
    GET_VERIFICATION_ORDER_BY_ID_LOADING = "GET_VERIFICATION_ORDER_BY_ID_LOADING",
    GET_VERIFICATION_ORDER_BY_ID_SUCCESS = "GET_VERIFICATION_ORDER_BY_ID_SUCCESS",
    GET_VERIFICATION_ORDER_BY_ID_FAILURE = "GET_VERIFICATION_ORDER_BY_ID_FAILURE",
    CLEAR_VERIFICATION_ORDER_SUCCESS = "CLEAR_VERIFICATION_ORDER_SUCCESS",
    CREATE_VERIFICATION_ERROR_LOADING = "CREATE_VERIFICATION_ERROR_LOADING",
    CREATE_VERIFICATION_ERROR_SUCCESS = "CREATE_VERIFICATION_ERROR_SUCCESS",
    CREATE_VERIFICATION_ERROR_FAILURE = "CREATE_VERIFICATION_ERROR_FAILURE",
    NEXT_STEP_VERIFICATION_LOADING = "NEXT_STEP_VERIFICATION_LOADING",
    NEXT_STEP_VERIFICATION_SUCCESS = "NEXT_STEP_VERIFICATION_SUCCESS",
    NEXT_STEP_VERIFICATION_FAILURE = "NEXT_STEP_VERIFICATION_FAILURE",
    GET_VERIFICATION_STATISTICS_LOADING = "GET_VERIFICATION_STATISTICS_LOADING",
    GET_VERIFICATION_STATISTICS_SUCCESS = "GET_VERIFICATION_STATISTICS_SUCCESS",
    GET_VERIFICATION_STATISTICS_FAILURE = "GET_VERIFICATION_STATISTICS_FAILURE",
}
