import { IncidenceFilters, Incidence } from "../../interfaces/incidence.interface";
import { IncidenceTypes } from "../types/incidence.types";
import { IncidenceProcess } from "../../api/process/incidence.process";
export const getIncidencesAction =
    (filters: IncidenceFilters) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: IncidenceTypes.GET_INCIDENCES_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const incidenceProcess = new IncidenceProcess();
            const response = await incidenceProcess.getIncidences(filters);
            return dispatch({
                type: IncidenceTypes.GET_INCIDENCES_SUCCESS,
                payload: {
                    incidences: response.data,
                    meta: response.meta,
                    filters,
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            return dispatch({
                type: IncidenceTypes.GET_INCIDENCES_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };
export const getIncidenceByIdAction = (incidenceId: number) => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: IncidenceTypes.GET_INCIDENCE_BY_ID_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const incidenceProcess = new IncidenceProcess();
        const response = await incidenceProcess.getIncidenceById(incidenceId);
        return dispatch({
            type: IncidenceTypes.GET_INCIDENCE_BY_ID_SUCCESS,
            payload: {
                selectedIncidence: response,
                loading: false,
            },
        });
    } catch (error) {
        return dispatch({
            type: IncidenceTypes.GET_INCIDENCE_BY_ID_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};
export const clearSelectedIncidenceAction = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: IncidenceTypes.CLEAR_INCIDENCE_SUCCESS,
        payload: {
            loading: false,
        },
    });
};
export const getIncidenceStatusAction = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: IncidenceTypes.GET_INCIDENCE_STATUS_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const incidenceProcess = new IncidenceProcess();
        const response = await incidenceProcess.getIncidenceStatus();
        return dispatch({
            type: IncidenceTypes.GET_INCIDENCE_STATUS_SUCCESS,
            payload: {
                incidenceStatus: response.incidenceStatus,
                loading: false,
                success: true,
            },
        });
    } catch (error) {
        return dispatch({
            type: IncidenceTypes.GET_INCIDENCE_STATUS_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};

export const updateIncidenceStatusAction =
    (incidenceList: Incidence[]) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: IncidenceTypes.UPDATE_INCIDENCE_STATUS_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const incidenceProcess = new IncidenceProcess();
            for (const incidence of incidenceList) {
                await incidenceProcess.updateIncidenceStatus({
                    idIncidence: incidence.id,
                    idStatus: incidence.statusId,
                });
            }
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_STATUS_SUCCESS,
                payload: {
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_STATUS_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };

export const getIncidenceVideoAction = (incidenceId: number) => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: IncidenceTypes.GET_INCIDENCE_VIDEOS_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const incidenceProcess = new IncidenceProcess();
        const response = await incidenceProcess.getIncidenceVideos(incidenceId);

        return dispatch({
            type: IncidenceTypes.GET_INCIDENCE_VIDEOS_SUCCESS,
            payload: {
                incidenceVideos: response.incidenceVideos,
                loading: false,
                success: true,
            },
        });
    } catch (error) {
        return dispatch({
            type: IncidenceTypes.GET_INCIDENCE_VIDEOS_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};
export const clearIncidenceVideoAction = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: IncidenceTypes.CLEAR_INCIDENCE_VIDEOS_SUCCESS,
        payload: {
            loading: false,
        },
    });
};
