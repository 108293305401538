import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../../configurations";
import { CommissionFilters, Commission, Wallet, PayCommissions, PaymentMethod } from "../../interfaces";

type CommissionServeData = {
    data: Commission[];
    meta: {
        page: number;
        take: number;
        itemCount: number;
        pageCount: number;
        hasPreviousPage: boolean;
        hasNextPage: boolean;
    };
};
export class CommissionRepository {
    private lmBootApi: AxiosInstance;

    constructor() {
        this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
    }

    getCommissions(data: CommissionFilters): Promise<AxiosResponse<CommissionServeData>> {
        return this.lmBootApi.get<CommissionServeData>("/commissions", { params: data });
    }
    getCommissionsInfo(data: string): Promise<AxiosResponse<Commission[]>> {
        return this.lmBootApi.get<Commission[]>("/commissions/info", { params: { ids: data } });
    }
    getWalletByClientCode(clientCode: string): Promise<AxiosResponse<Wallet>> {
        return this.lmBootApi.get<Wallet>(`wallet/${clientCode}`);
    }
    createPayCommissions(data: PayCommissions): Promise<AxiosResponse<{ message: string }>> {
        return this.lmBootApi.post<{ message: string }>(`/commissions/pay`, data);
    }
    getPaymentMethodList(): Promise<AxiosResponse<PaymentMethod[]>> {
        return this.lmBootApi.get<PaymentMethod[]>("/payment-method");
    }
}
