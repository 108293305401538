export enum CommissionTypes {
    GET_COMMISSIONS_LOADING = "GET_COMMISSIONS_LOADING",
    GET_COMMISSIONS_SUCCESS = "GET_COMMISSIONS_SUCCESS",
    GET_COMMISSIONS_FAILURE = "GET_COMMISSIONS_FAILURE",
    GET_COMMISSIONS_INFO_LOADING = "GET_COMMISSIONS_INFO_LOADING",
    GET_COMMISSIONS_INFO_SUCCESS = "GET_COMMISSIONS_INFO_SUCCESS",
    GET_COMMISSIONS_INFO_FAILURE = "GET_COMMISSIONS_INFO_FAILURE",
    GET_WALLET_LOADING = "GET_WALLET_LOADING",
    GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS",
    GET_WALLET_FAILURE = "GET_WALLET_FAILURE",
    CREATE_PAY_COMMISSIONS_LOADING = "CREATE_PAY_COMMISSIONS_LOADING",
    CREATE_PAY_COMMISSIONS_SUCCESS = "CREATE_PAY_COMMISSIONS_SUCCESS",
    CREATE_PAY_COMMISSIONS_FAILURE = "CREATE_PAY_COMMISSIONS_FAILURE",
    CLEAR_COMMISSIONS_SUCCESS = "CLEAR_COMMISSIONS_SUCCESS",
    GET_PAYMENT_METHOD_LOADING = "GET_PAYMENT_METHOD_LOADING",
    GET_PAYMENT_METHOD_SUCCESS = "GET_PAYMENT_METHOD_SUCCESS",
    GET_PAYMENT_METHOD_FAILURE = "GET_PAYMENT_METHOD_FAILURE",
}
