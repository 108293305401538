import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../../configurations";
import { Incidence, IncidenceVideo } from "../../interfaces";
import { IncidenceFilters, IncidenceResponse, IncidenceStatus } from "../../interfaces/incidence.interface";

export class IncidenceRepository {
    private lmBootApi: AxiosInstance;

    constructor() {
        this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
    }

    getIncidences(data: IncidenceFilters): Promise<AxiosResponse<IncidenceResponse>> {
        return this.lmBootApi.get<IncidenceResponse>("/incidences/web", { params: data });
    }
    getIncidenceById(incidenceId: number): Promise<AxiosResponse<Incidence>> {
        return this.lmBootApi.get<Incidence>(`/incidences/${incidenceId}`);
    }
    getIncidenceInfo(data: string): Promise<AxiosResponse<Incidence[]>> {
        return this.lmBootApi.get<Incidence[]>("/incidences/web", { params: { ids: data } });
    }
    getIncidenceStatus(): Promise<AxiosResponse<IncidenceStatus[]>> {
        return this.lmBootApi.get<IncidenceStatus[]>("/incidences/listStatus");
    }
    getIncidenceVideos(incidenceId: number): Promise<AxiosResponse<IncidenceVideo[]>> {
        return this.lmBootApi.get<IncidenceVideo[]>(`/incidences/videos/${incidenceId}`);
    }
    updateIncidenceStatus(data: {
        idIncidence: number;
        idStatus: number;
    }): Promise<AxiosResponse<{ message: string }>> {
        return this.lmBootApi.put<{ message: string }>(`/incidences/updateStatus`, data);
    }
}
