import { CommissionTypes } from "../types";
import { CommissionStore } from "../../interfaces/commission.interface";
import { AnyAction } from "redux";

const initialState: CommissionStore = {
    commissionList: null,
    selectedCommissionsInfo: null,
    filters: null,
    wallet: null,
    paymentMethodList: null,
    loading: false,
    success: true,
};

export const commissions = (
    state = initialState,
    action: { type: string; payload: CommissionStore } | AnyAction,
): CommissionStore => {
    const { loading, success, commissionList, filters, selectedCommissionsInfo, wallet, paymentMethodList } =
        action.payload || {};
    switch (action.type) {
        case CommissionTypes.GET_COMMISSIONS_LOADING:
            return {
                ...state,
                loading,
            };
        case CommissionTypes.GET_COMMISSIONS_SUCCESS:
            return {
                ...state,
                commissionList,
                filters,
                loading,
                success,
            };
        case CommissionTypes.GET_COMMISSIONS_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case CommissionTypes.GET_COMMISSIONS_INFO_LOADING:
            return {
                ...state,
                loading,
            };
        case CommissionTypes.GET_COMMISSIONS_INFO_SUCCESS:
            return {
                ...state,
                selectedCommissionsInfo,
                loading,
                success,
            };
        case CommissionTypes.GET_COMMISSIONS_INFO_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case CommissionTypes.GET_WALLET_LOADING:
            return {
                ...state,
                loading,
            };
        case CommissionTypes.GET_WALLET_SUCCESS:
            return {
                ...state,
                wallet,
                loading,
                success,
            };
        case CommissionTypes.GET_WALLET_FAILURE:
            return {
                ...state,
                wallet: null,
                loading,
                success,
            };
        case CommissionTypes.CREATE_PAY_COMMISSIONS_LOADING:
            return {
                ...state,
                loading,
            };
        case CommissionTypes.CREATE_PAY_COMMISSIONS_SUCCESS:
            return {
                ...state,
                loading,
                success,
            };
        case CommissionTypes.CREATE_PAY_COMMISSIONS_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case CommissionTypes.CLEAR_COMMISSIONS_SUCCESS:
            return {
                ...state,
                selectedCommissionsInfo: null,
                wallet: null,
            };
        case CommissionTypes.GET_PAYMENT_METHOD_LOADING:
            return {
                ...state,
                loading,
            };
        case CommissionTypes.GET_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                loading,
                success,
                paymentMethodList,
            };
        case CommissionTypes.GET_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        default:
            return state;
    }
};
