import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../../configurations";
import { AuthResponse } from "../../interfaces/auth.interface";

export class AuthRepository {
    private lmBootApi: AxiosInstance;

    constructor() {
        this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
    }

    getTokenAuthWithUserName(username: string, apikey: string): Promise<AxiosResponse<AuthResponse>> {
        return this.lmBootApi.post<AuthResponse>("/auth/token", { username, apikey });
    }
    loginUserWithUsernameAndPassword(username: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return this.lmBootApi.post<AuthResponse>("/auth/login-web", { username, password });
    }
}
