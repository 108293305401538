export const load = (item: string): string | null => {
    try {
        return localStorage.getItem(item);
    } catch (err) {
        return null;
    }
};
export const save = (item: string, data: any): void => {
    try {
        localStorage.setItem(item, data);
    } catch (err) {
        //Ignore write when is error
    }
};
export const loadObject = (item: string): any => {
    try {
        const serializedState = localStorage.getItem(item);
        return JSON.parse(serializedState ? serializedState : "");
    } catch (err) {
        return null;
    }
};
export const saveObject = (item: string, state: any): void => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(item, serializedState);
    } catch (err) {
        //Ignore write when is error
    }
};
export const deleteItem = (item: string): void => {
    try {
        localStorage.removeItem(item);
    } catch (err) {
        //Ignore write when is error
    }
};

export const clear = (): void => {
    try {
        localStorage.clear();
    } catch (err) {
        //Ignore write when is error
    }
};
