import React from "react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { Provider } from "react-redux";
import "./App.css";
import { AppRouter } from "./routers/AppRouter";
import { store } from "./redux/store";
import theme from "./theme/index";
import { SnackbarMessage } from "./components/shared/SnackbarMessage";

const App: React.FC<{}> = (): JSX.Element => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <SnackbarMessage />
                <AppRouter />
            </ThemeProvider>
        </Provider>
    );
};

export default App;
