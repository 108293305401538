export enum IncidenceTypes {
    GET_INCIDENCES_LOADING = "GET_INCIDENCES_LOADING",
    GET_INCIDENCES_SUCCESS = "GET_INCIDENCES_SUCCESS",
    GET_INCIDENCES_FAILURE = "GET_INCIDENCES_FAILURE",
    GET_INCIDENCE_STATUS_LOADING = "GET_INCIDENCE_STATUS_LOADING",
    GET_INCIDENCE_STATUS_SUCCESS = "GET_INCIDENCE_STATUS_SUCCESS",
    GET_INCIDENCE_STATUS_FAILURE = "GET_INCIDENCE_STATUS_FAILURE",
    UPDATE_INCIDENCE_STATUS_LOADING = "UPDATE_INCIDENCE_STATUS_LOADING",
    UPDATE_INCIDENCE_STATUS_SUCCESS = "UPDATE_INCIDENCE_STATUS_SUCCESS",
    UPDATE_INCIDENCE_STATUS_FAILURE = "UPDATE_INCIDENCE_STATUS_FAILURE",
    GET_INCIDENCE_BY_ID_LOADING = "GET_INCIDENCE_BY_ID_LOADING",
    GET_INCIDENCE_BY_ID_SUCCESS = "GET_INCIDENCE_BY_ID_SUCCESS",
    GET_INCIDENCE_BY_ID_FAILURE = "GET_INCIDENCE_BY_ID_FAILURE",
    CLEAR_INCIDENCE_SUCCESS = "CLEAR_INCIDENCE_SUCCESS",
    GET_INCIDENCE_VIDEOS_LOADING = "GET_INCIDENCE_VIDEOS_LOADING",
    GET_INCIDENCE_VIDEOS_SUCCESS = "GET_INCIDENCE_VIDEOS_SUCCESS",
    GET_INCIDENCE_VIDEOS_FAILURE = "GET_INCIDENCE_VIDEOS_FAILURE",
    CLEAR_INCIDENCE_VIDEOS_SUCCESS = "CLEAR_INCIDENCE_VIDEOS_SUCCESS",
}
