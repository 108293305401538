import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../../configurations";
import { Order, OrderFilters, Steps } from "../../interfaces";

type OrderServeData = {
    data: Order[];
    meta: {
        page: number;
        take: number;
        itemCount: number;
        pageCount: number;
        hasPreviousPage: boolean;
        hasNextPage: boolean;
    };
};
export class OrderRepository {
    private lmBootApi: AxiosInstance;

    constructor() {
        this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
    }

    getOrders(data: OrderFilters): Promise<AxiosResponse<OrderServeData>> {
        return this.lmBootApi.get<OrderServeData>("/commissions/dashboard", { params: data });
    }
    getSteps(): Promise<AxiosResponse<Steps[]>> {
        return this.lmBootApi.get<Steps[]>("/steps");
    }
}
