import { createTheme } from "@material-ui/core/styles";

const primaryColors = {
    dark: "#381014",
    second: "#54181F",
    main: "#712029",
    ligth: "#B73443",
    lighter: "#D7707C",
};

const secondaryColors = {
    dark: "#101519",
    second: "#181F25",
    main: "#202A32",
    ligth: "#4A6073",
    lighter: "#7C96AB",
};
const infoColors = {
    dark: "#67421B",
    second: "#9A6329",
    main: "#CA843A",
    light: "#D8A36B",
    lighter: "#E5C29C",
};

export const theme = createTheme({
    palette: {
        primary: primaryColors,
        secondary: secondaryColors,
        info: infoColors,
    },
    typography: {
        fontFamily: ["-apple-system", "Lato", "Muli", "sans-serif"].join(","),
    },
    breakpoints: {
        // Define custom breakpoint values.
        // These will apply to Material-UI components that use responsive
        // breakpoints, such as `Grid` and `Hidden`. You can also use the
        // theme breakpoint functions `up`, `down`, and `between` to create
        // media queries for these breakpoints
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1800,
        },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    backgroundColor: "#f5f6f8",
                },
            },
        },
    },
});

export default theme;
