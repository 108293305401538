import { showAlert } from "./alert";
import { VerificationProcess } from "../../api";
import { VerificationError, VerificationOrderFilters } from "../../interfaces";
import { VerificationTypes } from "../types";

export const getVerificationOrdersAction =
    (filters: VerificationOrderFilters) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: VerificationTypes.GET_VERIFICATION_ORDERS_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const verificationProcess = new VerificationProcess();
            const response = await verificationProcess.getVerificationOrders(filters);
            return dispatch({
                type: VerificationTypes.GET_VERIFICATION_ORDERS_SUCCESS,
                payload: {
                    verificationOrders: response.data,
                    meta: response.meta,
                    filters,
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            return dispatch({
                type: VerificationTypes.GET_VERIFICATION_ORDERS_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };
export const getVerificationOrderByIdAction =
    (orderCode: number) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: VerificationTypes.GET_VERIFICATION_ORDER_BY_ID_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const verificationProcess = new VerificationProcess();
            const response = await verificationProcess.getVerificationOrderById(orderCode);
            return dispatch({
                type: VerificationTypes.GET_VERIFICATION_ORDER_BY_ID_SUCCESS,
                payload: {
                    selectedVerificationOrder: response,
                    loading: false,
                },
            });
        } catch (error) {
            return dispatch({
                type: VerificationTypes.GET_VERIFICATION_ORDER_BY_ID_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };
export const clearSelectedVerificationOrderAction = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: VerificationTypes.CLEAR_VERIFICATION_ORDER_SUCCESS,
        payload: {
            loading: false,
        },
    });
};

export const createVerificationErrorsAction =
    (data: VerificationError) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: VerificationTypes.CREATE_VERIFICATION_ERROR_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const verificationProcess = new VerificationProcess();
            const response = await verificationProcess.createVerificationErrors(data);
            dispatch(showAlert("success", response.message));
            return dispatch({
                type: VerificationTypes.CREATE_VERIFICATION_ERROR_SUCCESS,
                payload: {
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            dispatch(showAlert("error", "Could not save errors!"));
            return dispatch({
                type: VerificationTypes.CREATE_VERIFICATION_ERROR_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };
export const nextStepVerificationAction =
    (orderCode: number) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: VerificationTypes.NEXT_STEP_VERIFICATION_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const verificationProcess = new VerificationProcess();
            const response = await verificationProcess.nextStepVerification(orderCode);
            dispatch(showAlert("success", response.message));
            return dispatch({
                type: VerificationTypes.NEXT_STEP_VERIFICATION_SUCCESS,
                payload: {
                    loading: false,
                    success: true,
                },
            });
        } catch (error: any) {
            const { message } = error.response.data || {};
            dispatch(showAlert("error", message || "The order cannot be advanced to the next step!"));
            return dispatch({
                type: VerificationTypes.NEXT_STEP_VERIFICATION_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };

export const getVerificationStatisticsAction = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: VerificationTypes.GET_VERIFICATION_STATISTICS_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const verificationProcess = new VerificationProcess();
        const response = await verificationProcess.getVerificationStatistics();
        return dispatch({
            type: VerificationTypes.GET_VERIFICATION_STATISTICS_SUCCESS,
            payload: {
                verificationStatistics: response,
                loading: false,
                success: true,
            },
        });
    } catch (error) {
        return dispatch({
            type: VerificationTypes.GET_VERIFICATION_STATISTICS_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};
